import axios, { AxiosInstance, AxiosResponse } from "axios";
import { RoadReport } from "sonar/types/RoadReport";
import { CreateRoadReport } from "sonar/types/CreateRoadReport";
import { DeleteRoadReport } from "sonar/types/DeleteRoadReport";
import { Filters } from "./types/Filters";

export function getURL() {
  let BASE_URL = "";
  const { NODE_ENV } = process.env;

  let modifiedNodeEnv = NODE_ENV as any;
  if (!modifiedNodeEnv || modifiedNodeEnv === "local") {
    BASE_URL = "https://sonar-dev.routing.dev-rvlife.com";
  } else if (modifiedNodeEnv === "development") {
    BASE_URL = "https://sonar-dev.routing.dev-rvlife.com";
  } else if (modifiedNodeEnv === "staging") {
    BASE_URL = "https://sonar-dev.routing.dev-rvlife.com";
  } else {
    BASE_URL = "https://sonar-prod.routing.rvlife.com";
  }
  return BASE_URL;
}

class SonarAPI {
  private api: AxiosInstance;

  constructor(keycloakToken: string | null) {
    this.api = axios.create({
      baseURL: getURL(),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloakToken,
      },
    });
  }

  public async getRoadReportsWithinBox(
    boundingBox: {
      xmin: number;
      ymin: number;
      xmax: number;
      ymax: number;
    },
    filters: Filters | undefined,
    groupByDistance: number | undefined,
    includeExpired: boolean
  ): Promise<RoadReport[]> {

    // maybe limit by map zoom?

    try {
      if (!filters) {
        filters = {};
      }
      let data = {
        ...boundingBox,
        filters: filters,
        includeExpired: includeExpired,
      };
      if (groupByDistance) {
        // @ts-ignore
        data["groupByDistance"] = groupByDistance;
      }
      const response = await this.api.post("/blips/mapbox", data);
      return response.data.result;
    } catch (error) {
      throw new Error(`Failed to make GET request to /blips: ${error}`);
    }
  }

  public async getRoadReportsAlongPolylines(
    searchQuery: string[],
    bufferSize: number,
    filters: Filters | undefined,
    groupByDistance: number | undefined,
    includeExpired: boolean
  ): Promise<RoadReport[]> {
    try {
      if (!filters) {
        filters = {};
      }
      let data = {
        polylines: searchQuery,
        bufferSize: bufferSize,
        filters: filters,
        includeExpired: includeExpired,
      };
      if (groupByDistance) {
        // @ts-ignore
        data["groupByDistance"] = groupByDistance;
      }

      const response = await this.api.post("/blips/polyline", data);
      return response.data.result;
    } catch (error) {
      throw new Error(`Failed to make GET request to /blips: ${error}`);
    }
  }

  public async createRoadReport(data: CreateRoadReport): Promise<AxiosResponse> {
    let endpoint = "/blips";
    try {
      const response = await this.api.post(endpoint, data);
      return response;
    } catch (error) {
      throw new Error(`Failed to make POST request to ${endpoint}: ${error}`);
    }
  }

  public async deleteRoadReport(data: DeleteRoadReport): Promise<AxiosResponse> {
    let endpoint = `/blips/${data.id}`;
    try {
      const response = await this.api.delete(endpoint);
      return response;
    } catch (error) {
      throw new Error(`Failed to make DELETE request to ${endpoint}: ${error}`);
    }
  }

  public async addStillThere(
    id: String,
    recorded_by: number
  ): Promise<AxiosResponse> {
    let endpoint = `blips/${id}/still-there`;
    try {
      const response = await this.api.post(endpoint, {
        recorded_by: recorded_by,
      });
      return response;
    } catch (error) {
      throw new Error(`Failed to make POST request to ${endpoint}: ${error}`);
    }
  }

  public async deleteStillThere(
    roadReportId: String,
    stillThereId: number
  ): Promise<AxiosResponse> {
    let endpoint = `blips/${roadReportId}/still-there/${stillThereId}`;
    try {
      const response = await this.api.delete(endpoint);
      return response;
    } catch (error) {
      throw new Error(`Failed to make DELETE request to ${endpoint}: ${error}`);
    }
  }

  public async getById(id: String): Promise<RoadReport> {
    let endpoint = `/blips/${id}`;
    try {
      const response = await this.api.get(endpoint);
      return response.data.result[0];
    } catch (error) {
      throw new Error(`Failed to make GET request to ${endpoint}: ${error}`);
    }
  }

  public async updateRoadReport(data: RoadReport): Promise<AxiosResponse> {
    let id = data.id;
    let dataCopy = { ...data };
    let endpoint = `/blips/${id}`;

    // Remove properties that are not needed for the update
    if (data.hasOwnProperty("still_there")) {
      // @ts-ignore
      delete dataCopy?.still_there;
      // @ts-ignore
      delete dataCopy?.id;
      // @ts-ignore
      delete dataCopy?.created_at;
      // @ts-ignore
      delete dataCopy?.geom;
      // @ts-ignore
      delete dataCopy?.blip_id;
    }

    console.log("data", dataCopy);
    try {
      const response = await this.api.put(endpoint, dataCopy);
      return response;
    } catch (error) {
      throw new Error(`Failed to make PUT request to ${endpoint}: ${error}`);
    }
  }
}

// Add more methods for other HTTP methods (e.g., PUT, DELETE) as needed

export default SonarAPI;
