import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { V8RouteResponse } from "map/types";
import _ from "lodash";
import { RouteKey } from "stores/types";
import { selectVisibleRoutes, toggleRoute } from "stores/ui";
import { useAppDispatch, useAppSelector } from "stores/hooks";

const RouteBox: React.FC<{
  route?: V8RouteResponse;
  label?: string;
  id: RouteKey;
}> = ({ route, label, id }) => {
  const dispatch = useAppDispatch();
  const isRouteVisible = useAppSelector(selectVisibleRoutes(id));
  if (!route) {
    return null;
  }
  return (
    <Stack direction="row">
      <div>
        <Checkbox
          checked={isRouteVisible}
          onChange={() => dispatch(toggleRoute(id))}
        />
      </div>
      <Accordion sx={{ width: "90%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{label || `${_.capitalize(id)}`} Route</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div id="car-panel">
            {route.routes[0].sections.map((section) => {
              return section.actions?.map((action) => {
                return <div key={action.instruction}>{action.instruction}</div>;
              });
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default RouteBox;
