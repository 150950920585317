import React, { useEffect, useMemo, useState } from "react";
import { useHereMap } from "map/Context";
import LocationMarker from "../assets/svg/location-marker.svg";
import { RoadReportIcons } from "./Icons/RoadReportIcons";

import { RouteArrowsStyle, RouteOutlineStyle } from "support/styles/MapStyles";
import { MapObjectEvent } from "map/types";
import { createIcon } from "map/util/createIcon";
import Marker from "map/elements/Marker";
import { Position } from "util/flexible-polyline";
import { RoadReport } from "./types/RoadReport";

interface MapInterfaceProps {
  onBoundingBoxChange: (boundingBox: {
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
  }) => void;
  onMapTapped?: (lat: number, lon: number) => void;
  roadReports: RoadReport[];
  polylines?: string[];
  onMarkerTapped: (roadReport: MapObjectEvent) => void;
  adjustView?: boolean;
  updateRoadReportPosition: (lat: number, lon: number, dragID: String) => void;
  draggedRoadReport?: RoadReport;
  resetDragged: boolean;
}

let offsetX = 0; // Calculate or set this based on your observations
let offsetY = -50; // Example adjustment based on your description

const MapInterface: React.FC<MapInterfaceProps> = ({
  roadReports,
  polylines,
  onMarkerTapped,
  updateRoadReportPosition,
  resetDragged,
}) => {
  const { map, behavior, ui } = useHereMap();
  const [isDragging, setIsDragging] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<[number, number]>([
    0, 0,
  ]);
  const [, setIsHovered] = useState(false);
  const [selectedRoadReport, setSelectedRoadReport] = useState<RoadReport | null>(null);
  const [roadReportPolyline, setRoadReportPolyline] = useState<H.map.Group | null>(null);

  window.addEventListener("resize", function () {
    map?.getViewPort().resize();
  });

  map?.addEventListener("dragstart", function () {
    ui?.getBubbles().forEach((bubble) => {
      bubble.close();
    });
  });

  const routeLine = useMemo(() => new H.map.Group(), []);
  // render the road reports on the map

  //render the polyline on the map when we have it
  useEffect(() => {
    if (!map) {
      return;
    }
    if (polylines) {
      try {
        //delete the old group first
        if (roadReportPolyline) {
          map?.removeObject(roadReportPolyline);
        }

        const linestring = H.geo.LineString.fromFlexiblePolyline(polylines[0]);

        const routeOutline = new H.map.Polyline(linestring, {
          style: RouteOutlineStyle,
          data: undefined,
        });
        const routeArrows = new H.map.Polyline(linestring, {
          style: RouteArrowsStyle,
          data: undefined,
        });
        routeLine.addObjects([routeOutline, routeArrows]);

        map?.addObjects([routeLine]);
        map?.getViewPort().setPadding(200, 200, 200, 200);
        map
          ?.getViewModel()
          .setLookAtData({ bounds: routeLine.getBoundingBox() });
        setRoadReportPolyline(routeLine);
      } catch (e) {
        console.log(e);
      }
    }
  }, [roadReportPolyline, map, polylines, routeLine]);

  const onPointerEnter = () => {
    if (map) {
      map.getViewPort().element.style.cursor = "pointer";
    }
    setIsHovered(true);
  };

  const onPointerLeave = () => {
    if (map) {
      map.getViewPort().element.style.cursor = "";
    }
    setIsHovered(false);
  };

  const onPointerMove = React.useCallback(
    (event: PointerEvent) => {
      if (!isDragging || !map) return;
      // Convert screen coordinates to geo coordinates
      const pointerPosition = map.screenToGeo(
        event.clientX + offsetX,
        event.clientY + offsetY
      );
      if (!pointerPosition) return;
      setCurrentPosition([pointerPosition.lat, pointerPosition.lng]);
    },
    [isDragging, map]
  );

  const onPointerDown = (event: MapObjectEvent) => {
    setIsDragging(true); // Start dragging
    setSelectedRoadReport(event.target?.getData());

    behavior && behavior.disable(H.mapevents.Behavior.Feature.PANNING);

    event.stopPropagation();
  };

  useEffect(() => {
    console.log("resetDragged", resetDragged);
    if (resetDragged) {
      setSelectedRoadReport(null);
    }

    const handlePointerUp = (event: PointerEvent) => {
      if (!isDragging) return;
      if (!map) return;
      behavior && behavior.enable(H.mapevents.Behavior.Feature.PANNING);
      const pointerPosition = map.screenToGeo(
        event.clientX + offsetX,
        event.clientY + offsetY
      );
      if (!pointerPosition) return;
      const newPosition: Position = [pointerPosition.lat, pointerPosition.lng];

      // Update the position in the global state/store
      updateRoadReportPosition(
        newPosition[0],
        newPosition[1],
        selectedRoadReport?.id ?? "0"
      );
      setIsDragging(false); // Reset dragging state
    };

    if (isDragging) {
      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", handlePointerUp); // Assuming handlePointerUp is defined
    } else {
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
    }

    return () => {
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
    };
  }, [
    isDragging,
    map,
    behavior,
    onPointerMove,
    resetDragged,
    updateRoadReportPosition,
    selectedRoadReport?.id,
  ]);

  return (
    <>
      {roadReports.map((roadReport, index) => {
        return (
          <Marker
            key={roadReport.id}
            position={
              roadReport.id === selectedRoadReport?.id
                ? currentPosition
                : { lat: roadReport.lat, lng: roadReport.lon }
            }
            data={roadReport}
            icon={createIcon({
              iconUrl: roadReport.hazard_type
                ? RoadReportIcons[roadReport.hazard_type]
                : null || LocationMarker,
              iconSize: [40, 40],
              glow: roadReport.id === selectedRoadReport?.id,
            })}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onTap={(event) => {
              setIsDragging(false);
              onMarkerTapped(event);
            }}
            onPointerDown={onPointerDown}
          />
        );
      })}
    </>
  );
};

export default MapInterface;