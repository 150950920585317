import { LatLngLiteral } from "map/types";
import {
  feetToCentimeters,
  feetToMeters,
  poundsToKg,
  poundsToMetricTons,
} from "util/conversion";
import axios from "axios";
import { Position, decode as decodePolyline } from "util/flexible-polyline";
import { getURL } from "util/url";

const MapboxToken =
  "pk.eyJ1Ijoic29jaWFsa25vd2xlZGdlIiwiYSI6ImNsa2ExM2I1cDAyZ3Yzc3F2Nnhka2s4YnAifQ.NOBu0GlJFPrW0cv0NQYVcw"; // replace with your Mapbox Access token

export interface RouteParams {
  dirtRoad: boolean;
  tollRoad: boolean;
  highway: boolean;
  tunnel: boolean;
  ferry: boolean;
  propane: boolean;
  height: string;
  length: string;
  width: string;
  weight: string;
  car: boolean;
  truck: boolean;
  bus: boolean;
  compareParams: boolean;
  approachWaypoints: boolean;
  departureWaypoints: boolean;
  traffic: "disabled" | "longterm" | "current";
  alternatives: number;
}

export const fetchRoute = async (
  postObject: any,
  token: string,
  logout: () => void
) => {
  try {
    const response = await axios.post(
      `${getURL()}/v2/atlas-routing?source=atlas`,
      postObject,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      logout();
      console.error("Unauthorized: Please check your credentials.");
    } else {
      console.error("An error occurred:", error);
    }
    throw error;
  }
};

const getEncodedPolyline = async (
  origin: [number, number],
  destination: [number, number],
  height: string,
  weight: string
): Promise<string> => {
  const originRounded = origin.map((coord) => parseFloat(coord.toFixed(4))) as [
    number,
    number
  ];
  const destinationRounded = destination.map((coord) =>
    parseFloat(coord.toFixed(4))
  ) as [number, number];

  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${originRounded[1]},${originRounded[0]};${destinationRounded[1]},${destinationRounded[0]}.json?access_token=${MapboxToken}&geometries=geojson&max_height=${height}&max_weight=${weight}`;

  const response = await axios.get(url);

  if (response.status !== 200 || response.data.routes.length === 0) {
    throw new Error("Error getting route");
  }
  return response.data.routes[0].geometry;
};

const setupRouteObject = (
  params: RouteParams,
  origin: LatLngLiteral,
  destination: LatLngLiteral,
  avoidAreas?: string,
  waypoints?: Position[]
) => {
  return {
    avoidances: {
      toll: params.tollRoad,
      dirtRoad: params.dirtRoad,
      ferry: params.ferry,
      highway: params.highway,
      tunnel: params.tunnel,
      propane: params.propane,
      traffic: params.traffic === "longterm" ? undefined : params.traffic,
    },
    rv_info: {
      height: parseInt(feetToCentimeters(params.height || "0")),
      length: parseInt(feetToCentimeters(params.length || "0")),
      width: parseInt(feetToCentimeters(params.width || "0")),
      weight: parseInt(poundsToKg(params.weight || "0")),
    },
    routes: [
      {
        origin: [origin.lat, origin.lng],
        waypoints: waypoints || ([] as Position[]),
        destination: [destination.lat, destination.lng],
        alternatives: params.alternatives || undefined,
      },
    ],
    metric: true,
    routing_type: "rv", // Default type
    user_id: "0000000",
    avoidAreas,
  };
};

const handleRouteSuccess = (
  response: any,
  onSuccess: (a: any, b?: boolean) => any
) => {
  onSuccess(response.data.journeys[0]);
};

export const route = async (
  onSuccess: (a: any, b?: boolean) => any,
  onNPSuccess: (a: any) => any,
  onError: (a: Error) => any,
  token: string,
  params: RouteParams,
  logout: () => void,
  mapRef?: H.Map,
  origin?: LatLngLiteral,
  destination?: LatLngLiteral,
  avoidAreas?: string,
  approach?: WaypointApproach,
  departure?: WaypointApproach
) => {
  if (!mapRef || !origin || !destination) {
    return; // Early exit if mapRef, origin or destination is not provided
  }

  try {
    const waypoints = [];
    if (params.approachWaypoints && approach) {
      const decodedPolyline = decodePolyline(approach.polyline);
      waypoints.push(...decodedPolyline.polyline);
    }

    if (params.departureWaypoints && departure) {
      const decodedPolyline = decodePolyline(departure.polyline);
      waypoints.unshift(...decodedPolyline.polyline);
    }

    const routeObject = setupRouteObject(
      params,
      origin,
      destination,
      avoidAreas,
      waypoints
    );

    const encodedPolyline = await getEncodedPolyline(
      [origin.lat, origin.lng],
      [destination.lat, destination.lng],
      feetToMeters(params.height || "6").toString(),
      poundsToMetricTons(params.weight || "500").toString()
    );
    onSuccess(encodedPolyline, true);

    const fetchRouteTypes = ["car", "rv", "bus"];
    for (const type of fetchRouteTypes) {
      const updatedRouteObject = { ...routeObject, routing_type: type };
      const response = await fetchRoute(updatedRouteObject, token, logout);
      handleRouteSuccess(response, onSuccess);
    }

    if (params.compareParams) {
      routeObject.avoidances = {
        toll: false,
        dirtRoad: false,
        ferry: false,
        highway: false,
        tunnel: false,
        propane: false,
        traffic: "disabled",
      };

      for (const type of fetchRouteTypes) {
        const updatedRouteObject = { ...routeObject, routing_type: type };

        // TODO: DELETE THIS WHEN THE ROUTING SERVER IS RELEASED
        delete updatedRouteObject.avoidances.traffic;
        const response = await fetchRoute(updatedRouteObject, token, logout);
        handleRouteSuccess(response, onNPSuccess);
      }
    }
  } catch (error: any) {
    onError(error);
  }
};
