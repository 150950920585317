import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import JSONPretty from "react-json-pretty";

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  TextField,
  Grid,
} from "@mui/material";
import { CreateRoadReport, RvType, HazardType } from "sonar/types/CreateRoadReport";

interface CreateRoadReportModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (createRoadReport: CreateRoadReport) => void;
  jsonData: { lat: number; lon: number };
}

const CreateRoadReportModal: React.FC<CreateRoadReportModalProps> = ({
  open,
  onClose,
  onCreate,
  jsonData,
}) => {
  const [payload, setPayload] = React.useState<CreateRoadReport | null>(null);
  const [rv_height, setRvHeight] = React.useState<number | null>(null);
  const [rv_length, setRvLength] = React.useState<number | null>(null);
  const [rv_type, setRvType] = React.useState<RvType | null>(null);
  const [pm_user_id, setPmUserId] = React.useState<number | null>(null);
  const [hazard_type, setHazardType] = React.useState<HazardType | null>(null);
  const [rv_weight, setRvWeight] = React.useState<number | null>(null);
  const [low_clearance_height, setLowClearanceHeight] = React.useState<
    number | null
  >(null);
  const [weight_limit, setWeightLimit] = React.useState<number | null>(null);

  const resetModal = () => {
    setPayload(null);
    setRvHeight(null);
    setRvLength(null);
    setRvType(null);
    setPmUserId(null);
    setHazardType(null);
    setRvWeight(null);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setHazardType(event.target.value as HazardType);
    setPayload({
      ...jsonData,
      rv_height,
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height,
      weight_limit,
    });
  };

  const handleRvHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRvHeight(Number(event.target.value));
    setPayload({
      ...jsonData,
      rv_height: Number(event.target.value),
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height,
      weight_limit,
    });
  };

  const handleRvLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRvLength(Number(event.target.value));
    setPayload({
      ...jsonData,
      rv_height,
      rv_length: Number(event.target.value),
      rv_weight,
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height,
      weight_limit,
    });
  };

  const handleRvWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRvWeight(Number(event.target.value));
    setPayload({
      ...jsonData,
      rv_height,
      rv_length,
      rv_weight: Number(event.target.value),
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height,
      weight_limit,
    });
  };

  const handleWeightLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWeightLimit(Number(event.target.value));
    setPayload({
      ...jsonData,
      rv_height,
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height,
      weight_limit: Number(event.target.value),
    });
  };

  const handleRvTypeChange = (event: SelectChangeEvent) => {
    setRvType(event.target.value as RvType);
    setPayload({
      ...jsonData,
      rv_height,
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height,
      weight_limit,
    });
  };

  const handlePmUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPmUserId(Number(event.target.value));
    setPayload({
      ...jsonData,
      rv_height: rv_height,
      rv_length: rv_length,
      rv_weight: rv_weight,
      rv_type: rv_type,
      pm_user_id: Number(event.target.value),
      hazard_type: hazard_type,
      low_clearance_height: low_clearance_height,
      weight_limit: weight_limit,
    });
  };

  const handleLowClearanceHeightChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLowClearanceHeight(Number(event.target.value));
    setPayload({
      ...jsonData,
      rv_height,
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      hazard_type,
      low_clearance_height: Number(event.target.value),
      weight_limit,
    });
  };

  const handleCreate = () => {
    console.log("Create road report:", payload);
    if (!hazard_type) {
      alert("Please select a hazard type");
      return;
    }
    onCreate({
      lat: jsonData.lat,
      lon: jsonData.lon,
      hazard_type: hazard_type,
      rv_height,
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      low_clearance_height,
      weight_limit,
    });
    // reset the modal
    resetModal();
  };

  useEffect(() => {
    setPayload({
      ...jsonData,
      hazard_type: hazard_type,
      rv_height,
      rv_length,
      rv_weight,
      rv_type,
      pm_user_id,
      low_clearance_height,
      weight_limit,
    });
  }, [
    jsonData,
    hazard_type,
    rv_height,
    rv_length,
    rv_weight,
    rv_type,
    pm_user_id,
    low_clearance_height,
    weight_limit,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Create a road report?"}</DialogTitle>
      <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
      <Grid container spacing={2} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={hazard_type ?? ""}
              onChange={handleTypeChange}
              label="Hazard Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"low_clearance"}>Low Clearance</MenuItem>
              <MenuItem value={"no_trucks"}>No Trucks</MenuItem>
              <MenuItem value={"tunnel"}>Tunnel</MenuItem>
              <MenuItem value={"unpaved_road"}>Unpaved Road</MenuItem>
              <MenuItem value={"narrow_road"}>Narrow Road</MenuItem>
              <MenuItem value={"steep_grade_up"}>Steep Grade {`(Up)`}</MenuItem>
              <MenuItem value={"steep_grade_down"}>
                Steep Grade {`(Down)`}
              </MenuItem>
              <MenuItem value={"weight_limit"}>Weight Limit</MenuItem>
              <MenuItem value={"road_hazard"}>Road Hazard</MenuItem>
              <MenuItem value={"high_winds"}>High Winds</MenuItem>
              <MenuItem value={"snow"}>Snow</MenuItem>
              <MenuItem value={"flooding"}>Flooding</MenuItem>
              <MenuItem value={"road_construction"}>Road Construction</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleRvHeightChange}
            label="RV Height (cm)"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleRvLengthChange}
            label="RV Length (cm)"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleRvWeightChange}
            label="RV Weight (kg)"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="rv-type-label">RV Type</InputLabel>
            <Select
              labelId="rv-type-label"
              id="rv-type-select"
              value={rv_type ?? ""}
              onChange={handleRvTypeChange}
              label="RV Type"
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Motorhome"}>Motorhome</MenuItem>
              <MenuItem value={"Travel Trailer"}>Travel Trailer</MenuItem>
              <MenuItem value={"Fifth Wheel"}>Fifth Wheel</MenuItem>
              <MenuItem value={"Toy Hauler"}>Toy Hauler</MenuItem>
              <MenuItem value={"Tent Trailer"}>Tent Trailer</MenuItem>
              <MenuItem value={"Truck Camper"}>Truck Camper</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={handlePmUserIdChange}
            label="PM User ID"
            variant="standard"
            fullWidth
          />
        </Grid>
        {(hazard_type === "tunnel" || hazard_type === "low_clearance") && (
          <Grid item xs={12}>
            <TextField
              onChange={handleLowClearanceHeightChange}
              label={"low_clearance_height (cm) REQUIRED FOR " + hazard_type}
              variant="standard"
              fullWidth
            />
          </Grid>
        )}
        {hazard_type === "weight_limit" && (
          <Grid item xs={12}>
            <TextField
              onChange={handleWeightLimitChange}
              label={"weight_limit (kg) REQUIRED FOR " + hazard_type}
              variant="standard"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <JSONPretty
            id="json-pretty"
            data={{
              ...payload,
              hazard_type,
              rv_height,
              rv_length,
              rv_weight,
              rv_type,
              pm_user_id,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button onClick={handleCreate} autoFocus>
          CREATE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoadReportModal;