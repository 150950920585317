import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Link } from "@mui/material";
import { useHereMap } from "map/Context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StillThereDialog from "./SonarModals/StillThereDialog";
import { RoadReport } from "sonar/types/RoadReport";
import { metricToImperial } from "util/conversion";

interface RoadReportsTableProps {
  roadReports: RoadReport[];
}

// header style
let headerStyle = {
  backgroundColor: "#119758",
  color: "white",
};

const RoadReportsTable: React.FC<RoadReportsTableProps> = ({ roadReports }) => {
  const { map } = useHereMap();
  const [selectedRoadReport, setSelectedRoadReport] = useState<RoadReport | null>(null);
  const [stillThereRoadReport, setStillThereRoadReport] = useState<RoadReport | undefined>(
    undefined
  );
  const [selectedId, setSelectedId] = useState<string | null>(null); // Added state for selectedId

  if (roadReports.length === 0) {
    return <div></div>;
  }

  const jumpToRoadReport = (roadReport: RoadReport) => {
    let group = new H.map.Group();
    map?.getObjects(true).forEach((object) => {
      if (object instanceof H.map.Marker) {
        const data = object.getData();
        if (data && data.id === roadReport.id) {
          group.addObjects([object]);
          map.addObject(group);

          map?.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
            zoom: 15,
          });
        }
      }
    });
  };

  const handleOpenModal = (roadReport: RoadReport) => {
    setSelectedRoadReport(roadReport);
  };

  const handleCloseModal = () => {
    setSelectedRoadReport(null);
  };

  const handleRowClick = (id: string) => {
    setSelectedId(id);
  };
  const handleOpenStillThereModal = (roadReport: RoadReport) => {
    setStillThereRoadReport(roadReport);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={headerStyle}>
            <Tooltip title="Hover to View Latitude and Longitude, Click to jump to Road Report">
              <span>
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ marginLeft: "4px" }}
                />
                ID
              </span>
            </Tooltip>
          </TableCell>
          <TableCell style={headerStyle}>Hazard Type</TableCell>
          <TableCell style={headerStyle}>RV INFO</TableCell>
          <TableCell style={headerStyle}>STILL THERE ENTRIES</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {roadReports.map((roadReport) => (
          <TableRow
            key={roadReport.id}
            style={selectedId === roadReport.id ? { backgroundColor: "yellow" } : {}} // Highlight the row if selectedId matches roadReport.id
            onClick={() => handleRowClick(roadReport.id)} // Call handleRowClick when the row is clicked
          >
            <TableCell>
              <Tooltip title={`Latitude: ${roadReport.lat}, Longitude: ${roadReport.lon}`}>
                <Link
                  href="#"
                  onClick={() => {
                    jumpToRoadReport(roadReport);
                  }}
                >
                  id
                </Link>
              </Tooltip>
            </TableCell>
            <TableCell>{roadReport.hazard_type}</TableCell>
            <TableCell>
              <Button onClick={() => handleOpenModal(roadReport)}>RV Info</Button>
            </TableCell>

            <TableCell>
              {roadReport.still_there.length > 0 ? (
                <Button onClick={() => handleOpenStillThereModal(roadReport)}>
                  Entries
                </Button>
              ) : (
                <Button onClick={() => handleOpenStillThereModal(roadReport)}>
                  NO Entries
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <Dialog open={selectedRoadReport !== null} onClose={handleCloseModal}>
        <DialogTitle>RV Info</DialogTitle>
        <DialogContent>
          {selectedRoadReport && (
            <>
              <DialogContentText>
                RV Height: {selectedRoadReport.rv_height} cm (
                {metricToImperial(selectedRoadReport.rv_height, "cm")})
              </DialogContentText>
              <DialogContentText>
                RV Length: {selectedRoadReport.rv_length} cm (
                {metricToImperial(selectedRoadReport.rv_length, "cm")})
              </DialogContentText>
              <DialogContentText>
                RV Type: {selectedRoadReport.rv_type}
              </DialogContentText>
              <DialogContentText>
                PM User ID: {selectedRoadReport.pm_user_id}
              </DialogContentText>
              <DialogContentText>
                RV Weight: {selectedRoadReport.rv_weight} kg (
                {metricToImperial(selectedRoadReport.rv_weight, "kg")})
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
      <StillThereDialog
        stillThereRoadReport={stillThereRoadReport}
        onClose={() => setStillThereRoadReport(undefined)}
      />
    </Table>
  );
};

export default RoadReportsTable;
