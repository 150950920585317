import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "stores/store";
import { IBusZone } from "./types";
import {
  ABSENT,
  DEFAULT_PRECISION,
  Position,
  decode as decodePolyline,
  encode as encodePolyline,
} from "../util/flexible-polyline";
import axios from "axios";
import { getURL } from "util/url";
import { LatLngLiteral } from "map/types";
import _ from "lodash";
import { isPolylineBoundingBox } from "tools/AvoidArea/utils.avoidarea";

const initialState: {
  busZones: IBusZone[];
  visible: boolean;
  deletedVisible: boolean;
  editedBusZone: IBusZone | undefined;
  editedBusPolygon: IBusZone | undefined;
} = {
  busZones: [],
  visible: false,
  deletedVisible: false,
  editedBusZone: undefined,
  editedBusPolygon: undefined,
};

const emptyBusZone: IBusZone = {
  id: "",
  polyline: "BFAAAA",
  zoneName: "New Bus zone",
};

export const busZoneReducer = createSlice({
  name: "busZone",
  initialState,
  reducers: {
    toggleVisibility: (state) => {
      state.visible = !state.visible;
      console.log("Visibility toggled:", state.visible);
    },

    importBusZones: (state, action: PayloadAction<IBusZone[]>) => {
      state.busZones = action.payload;
      console.log("Imported bus zones:", state.busZones);
    },

    setTopLeftCoordinate: (state, action: PayloadAction<LatLngLiteral>) => {
      if (!state.editedBusZone) {
        state.editedBusZone = emptyBusZone;
        console.log("Created new edited bus zone.");
      }
      const encoded = state.editedBusZone.polyline;
      const decoded = decodePolyline(encoded);
      const decodedArray = decoded.polyline;
      decodedArray[0][0] = action.payload.lat;
      decodedArray[1][1] = action.payload.lng;
      const encodedCoordinates = encodePolyline({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: decodedArray,
      });
      state.editedBusZone.polyline = encodedCoordinates;
      console.log("Set top-left coordinate:", state.editedBusZone.polyline);
    },

    setBottomRightCoordinate: (state, action: PayloadAction<LatLngLiteral>) => {
      if (!state.editedBusZone) {
        state.editedBusZone = emptyBusZone;
        console.log("Created new edited bus zone.");
      }
      const encoded = state.editedBusZone.polyline;
      const decoded = decodePolyline(encoded);
      const decodedArray = decoded.polyline;
      decodedArray[1][0] = action.payload.lat;
      decodedArray[0][1] = action.payload.lng;
      const encodedCoordinates = encodePolyline({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: decodedArray,
      });
      state.editedBusZone.polyline = encodedCoordinates;
      console.log("Set bottom-right coordinate:", state.editedBusZone.polyline);
    },

    createEditedBusZone: (state) => {
      state.editedBusZone = emptyBusZone;
      state.visible = true;
      state.editedBusPolygon = undefined;
      console.log("Created new edited bus zone.");
    },

    setEditedBusZone: (state, action: PayloadAction<IBusZone>) => {
      if (isPolylineBoundingBox(action.payload.polyline)) {
        state.editedBusZone = action.payload;
        state.editedBusPolygon = undefined;
        console.log("Set edited bus zone:", state.editedBusZone);
      } else {
        state.editedBusPolygon = action.payload;
        state.editedBusZone = undefined;
        console.log("Set edited bus polygon:", state.editedBusPolygon);
      }
    },

    setNextBusPolygonVertex: (state, action: PayloadAction<LatLngLiteral>) => {
      if (!state.editedBusPolygon) {
        console.log("Enter edit bus polygon mode first.");
        return;
      }
      const encoded = state.editedBusPolygon.polyline;
      const decoded = decodePolyline(encoded);
      let decodedArray: Position[] = [];
      if (decoded) {
        decodedArray = decoded.polyline;
      }
      decodedArray.push([action.payload.lat, action.payload.lng]);
      const encodedCoordinates = encodePolyline({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: decodedArray,
      });
      state.editedBusPolygon.polyline = encodedCoordinates;
      console.log(
        "Set next bus polygon vertex:",
        state.editedBusPolygon.polyline
      );
    },

    deleteBusPolygonVertex: (state, action: PayloadAction<number>) => {
      if (!state.editedBusPolygon) {
        console.log("No edited bus polygon to delete vertex from.");
        return;
      }
      const encodedCoordinates = state.editedBusPolygon;
      const coordinates = decodePolyline(encodedCoordinates.polyline);
      if (coordinates) {
        const newCoordinates = coordinates.polyline.filter(
          (_, i) => i !== action.payload
        );

        state.editedBusPolygon.polyline = encodePolyline({
          polyline: newCoordinates,
        } as any);
      }
    },

    discardChanges: (state) => {
      state.editedBusZone = undefined;
      console.log("Discarded changes to edited bus zone.");
      console.log("Deselected active bus zone.");
      state.editedBusPolygon = undefined;
      console.log("Discarded changes to edited bus polygon.");
    },

    createEditedBusPolygon: (state) => {
      const clonedBusZone = _.cloneDeep(emptyBusZone);
      clonedBusZone.polyline = "";
      state.editedBusPolygon = clonedBusZone;
      state.editedBusZone = undefined;
      state.visible = true;
      console.log("Created new edited bus polygon.");
    },
  },
});

export const {
  importBusZones,
  toggleVisibility,
  discardChanges,
  setTopLeftCoordinate,
  setBottomRightCoordinate,
  setEditedBusZone,
  createEditedBusZone,
  createEditedBusPolygon,
  setNextBusPolygonVertex,
  deleteBusPolygonVertex,
} = busZoneReducer.actions;

export const isBusZonesVisible = (state: RootState) => state.busZone.visible;

export const getDeletedVisible = (state: RootState) =>
  state.busZone.deletedVisible;

export const returnZones = (state: RootState) => state.busZone.busZones;

export const getEditedBusZone = (state: RootState) =>
  state.busZone.editedBusZone;

export const getEditedBusPolygon = (state: RootState) =>
  state.busZone.editedBusPolygon;

export const fetchBusZones =
  (bearerToken: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios.get(getURL() + "/v2/map/atlas-busZones", {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      const jsonData = await response.data;
      const decodedBusZones = JSON.parse(jsonData.busZones);
      console.log("Fetched bus zones:", decodedBusZones);
      dispatch(importBusZones(decodedBusZones));
    } catch (error) {
      console.error("Failed to fetch bus zones:", error);
    }
  };

export const createBusZone =
  (busZone: IBusZone, bearerToken: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(getURL() + "/v2/map/busZone", busZone, {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      console.log("Submitted bus zone:", busZone);
      dispatch(discardChanges());
      dispatch(fetchBusZones(bearerToken));
    } catch (error) {
      console.error("Failed to submit bus zone:", error);
    }
  };

export const editBusZone =
  (busZone: IBusZone, bearerToken: string): AppThunk =>
  async (dispatch) => {
    const { editedBy, dateEdited, ...cleanedBusZone } = busZone;
    try {
      await axios.put(getURL() + "/v2/map/busZone", cleanedBusZone, {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      console.log("Updated bus zone:", busZone);
      dispatch(discardChanges());
      dispatch(fetchBusZones(bearerToken));
    } catch (error) {
      console.error("Failed to submit bus zone:", error);
    }
  };

export const deleteBusZone =
  (id: string, bearerToken: string): AppThunk =>
  async (dispatch) => {
    if (!id) {
      console.error("No id provided to delete bus zone.");
      dispatch(discardChanges());
      return;
    }
    try {
      await axios.delete(getURL() + `/v2/map/busZones/${id}`, {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      console.log("Deleted bus zone with id:", id);
      dispatch(discardChanges());
      dispatch(fetchBusZones(bearerToken));
    } catch (error) {
      console.error("Failed to delete bus zone:", error);
    }
  };

export const saveChanges =
  (bearerToken: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    if (state.busZone.editedBusZone && state.busZone.editedBusPolygon) {
      console.log(
        `This shouldn't happen technically but somehow editedBusZone and editedBusPolygon are both set. That's no good.`
      );
      return;
    }

    if (state.busZone.editedBusZone) {
      if (state.busZone.editedBusZone.id) {
        console.log("Editing existing bus zone.");
        dispatch(editBusZone(state.busZone.editedBusZone, bearerToken));
      } else {
        console.log("Creating new bus zone.");
        dispatch(createBusZone(state.busZone.editedBusZone, bearerToken));
      }
      console.log("Saved changes to bus zone.");
    }

    if (state.busZone.editedBusPolygon) {
      if (state.busZone.editedBusPolygon.id) {
        console.log("Editing existing bus polygon.");
        dispatch(editBusZone(state.busZone.editedBusPolygon, bearerToken));
      } else {
        console.log("Creating new bus polygon.");
        dispatch(createBusZone(state.busZone.editedBusPolygon, bearerToken));
      }
      console.log("Saved changes to bus polygon.");
    }
  };

export default busZoneReducer.reducer;
