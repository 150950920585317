import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Filters } from "../types/Filters";
interface FiltersModalProps {
  isOpen: boolean;
  setFilters: (filters: any) => void;
  onClose: () => void;
  filterUpdate: (filters: any) => void;
  filters: Filters | undefined;
}

const FiltersModal: React.FC<FiltersModalProps> = ({
  isOpen,
  setFilters,
  filters,
  onClose,
  filterUpdate,
}) => {
  const [id, setId] = useState<String | undefined>(filters?.id ?? undefined);
  const [rv_type, setRvType] = useState<String | undefined>(
    filters?.rv_type ?? undefined
  );
  const [rv_height, setRvHeight] = useState<number | undefined>(
    filters?.rv_height ?? undefined
  );
  const [rv_length, setRvLength] = useState<number | undefined>(
    filters?.rv_length ?? undefined
  );
  const [rv_weight, setRvWeight] = useState<number | undefined>(
    filters?.rv_weight ?? undefined
  );
  const [pm_user_id, setPmUserId] = useState<number | undefined>(
    filters?.pm_user_id ?? undefined
  );
  const [hazard_type, setHazardType] = useState<String | undefined>(
    filters?.hazard_type ?? undefined
  );
  const [road_report_id, setRoadReportId] = useState<String | undefined>(
    filters?.road_report_id ?? undefined
  );
  const [low_clearance_height, setLowClearanceHeight] = useState<
    number | undefined
  >(filters?.low_clearance_height ?? undefined);
  const [weight_limit, setWeightLimit] = useState<number | undefined>(
    filters?.weight_limit ?? undefined
  );
  const [narrow_road_width, setNarrowRoadWidth] = useState<number | undefined>(
    filters?.narrow_road_width ?? undefined
  );
  const [seed_source, setSeedSource] = useState<String | undefined>(
    filters?.seed_source ?? undefined
  );
  const [is_permanent, setIsPermanent] = useState<boolean | undefined>(
    filters?.is_permanent ?? undefined
  );
  const [verified, setVerified] = useState<boolean | undefined>(
    filters?.verified ?? undefined
  );
  const [verified_by, setVerifiedBy] = useState<String | undefined>(
    filters?.verified_by ?? undefined
  );
  const [structure_number, setStructureNumber] = useState<String | undefined>(
    filters?.structure_number ?? undefined
  );

  const handleSave = () => {
    // Logic to save the updated filters
    const updatedFilters = {
      id: id,
      rv_type: rv_type,
      rv_height: rv_height,
      rv_length: rv_length,
      rv_weight: rv_weight,
      pm_user_id: pm_user_id,
      hazard_type: hazard_type,
      road_report_id: road_report_id,
      low_clearance_height: low_clearance_height,
      weight_limit: weight_limit,
      narrow_road_width: narrow_road_width,
      seed_source: seed_source,
      is_permanent: is_permanent,
      verified: verified,
      verified_by: verified_by,
      structure_number: structure_number,
    };
    // clean out any undefined values or empty string values
    Object.keys(updatedFilters).forEach((key) =>
      updatedFilters[key as keyof typeof updatedFilters] === undefined ||
      updatedFilters[key as keyof typeof updatedFilters] === ""
        ? delete updatedFilters[key as keyof typeof updatedFilters]
        : {}
    );

    console.log(updatedFilters);
    setFilters(updatedFilters);
    filterUpdate(updatedFilters);
    onClose();
  };

  const onClearFilters = () => {
    setFilters(undefined);
    setId(undefined);
    setRvType(undefined);
    setRvHeight(undefined);
    setRvLength(undefined);
    setRvWeight(undefined);
    setPmUserId(undefined);
    setHazardType(undefined);
    setRoadReportId(undefined);
    setLowClearanceHeight(undefined);
    setWeightLimit(undefined);
    setNarrowRoadWidth(undefined);
    setSeedSource(undefined);
    setIsPermanent(undefined);
    setVerified(undefined);
    setVerifiedBy(undefined);
    setStructureNumber(undefined);
    console.log("Filters cleared");
  };

  const onChangeHandler = (name: String, value: String | number | boolean) => {
    console.log("name", name);
    console.log("value", value);

    switch (name) {
      case "id":
        setId(value as String);
        break;
      case "rv_type":
        setRvType(value as String);
        break;
      case "rv_height":
        setRvHeight(value as number);
        break;
      case "rv_length":
        setRvLength(value as number);
        break;
      case "rv_weight":
        setRvWeight(value as number);
        break;
      case "pm_user_id":
        setPmUserId(value as number);
        break;
      case "hazard_type":
        setHazardType(value as String);
        break;
      case "road_report_id":
        setRoadReportId(value as String);
        break;
      case "low_clearance_height":
        setLowClearanceHeight(value as number);
        break;
      case "weight_limit":
        setWeightLimit(value as number);
        break;
      case "narrow_road_width":
        setNarrowRoadWidth(value as number);
        break;
      case "seed_source":
        setSeedSource(value as String);
        break;
      case "is_permanent":
        setIsPermanent(value as boolean);
        break;
      case "verified":
        setVerified(value as boolean);
        break;
      case "verified_by":
        setVerifiedBy(value as String);
        break;
      case "structure_number":
        setStructureNumber(value as String);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="ID"
          type="text"
          name="id"
          fullWidth
          value={id || ""}
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        />
        <TextField
          margin="dense"
          label="RV Type"
          type="text"
          name="rv_type"
          fullWidth
          value={rv_type || ""}
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        />
        <TextField
          margin="dense"
          name="rv_height"
          label="RV Height"
          type="number"
          fullWidth
          value={rv_height || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          name="rv_length"
          label="RV Length"
          type="number"
          fullWidth
          value={rv_length || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          name="rv_weight"
          label="RV Weight"
          type="number"
          fullWidth
          value={rv_weight || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          label="PM User ID"
          type="number"
          name="pm_user_id"
          fullWidth
          value={pm_user_id || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          name="hazard_type"
          label="Hazard Type"
          type="text"
          fullWidth
          value={hazard_type || ""}
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        />
        <TextField
          margin="dense"
          name="road_report_id"
          label="Road Report ID"
          type="text"
          fullWidth
          value={road_report_id || ""}
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        />
        <TextField
          margin="dense"
          name="low_clearance_height"
          label="Low Clearance Height"
          type="number"
          fullWidth
          value={low_clearance_height || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          name="weight_limit"
          label="Weight Limit"
          type="number"
          fullWidth
          value={weight_limit || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          name="narrow_road_width"
          label="Narrow Road Width"
          type="number"
          fullWidth
          value={narrow_road_width || undefined}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
        <TextField
          margin="dense"
          label="Seed Source"
          name="seed_source"
          type="text"
          fullWidth
          value={seed_source || ""}
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        />
        <FormControlLabel
          label="Is Permanent"
          name="is_permanent"
          control={
            <Checkbox
              checked={is_permanent || false}
              onChange={(e) => onChangeHandler(e.target.name, e.target.checked)}
            />
          }
        />
        <FormControlLabel
          label="Is verified"
          name="verified"
          control={
            <Checkbox
              checked={verified || false}
              onChange={(e) => onChangeHandler(e.target.name, e.target.checked)}
            />
          }
        />
        <TextField
          margin="dense"
          label="Verified By"
          name="verified_by"
          type="text"
          fullWidth
          value={verified_by || ""}
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        />
        <TextField
          margin="dense"
          name="structure_number"
          label="Structure Number"
          type="text"
          fullWidth
          value={structure_number || ""}
          onChange={(e) =>
            onChangeHandler(e.target.name, Number(e.target.value))
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClearFilters} color="primary">
          Clear Filters
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FiltersModal;
